<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="title" size="lg">
        <div class="mb-4 text-center">
            <two-dates-picker :date-debut="dateDebut" :date-fin="dateFin"
                              @dateDebutChange="updateDateDebut" @dateFinChange="updateDateFin"/>
        </div>

        <confirm-cancel-buttons @confirm="confirm" @cancel="hideModal"/>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    const TwoDatesPicker       = () => import('@/components/TwoDatesPicker');
    const ConfirmCancelButtons = () => import('@/components/ConfirmCancelButtons');

    export default {
        name: "EditText",
        components: {TwoDatesPicker, ConfirmCancelButtons},
        mixins: [modalMixin],
        props: {
            title: {
                type: String,
                default: 'Édition'
            },
            dateDebut: {},
            dateFin: {},
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'EditTextModal',
            dateDebutInput: null,
            dateFinInput: null
        }),
        methods: {
            updateDateDebut(val) {
                this.dateDebutInput = val;
            },
            updateDateFin(val) {
                this.dateFinInput = val;
            },
            confirm() {
                this.callback(this.dateDebutInput, this.dateFinInput);
            }
        },
        mounted() {
            this.dateDebutInput = this.dateDebut;
            this.dateFinInput   = this.dateFin;
        }
    }
</script>
